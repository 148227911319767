import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ViewEncapsulation,
    signal,
    ViewChild,
    OnInit,
} from "@angular/core";
import { Debounce } from "../../../../decorators/debounce.decorator";
import { EventService } from "../../../../services/event.service";
import { PopoverComponent } from "../../../s25-popover/popover.component";
import { OccurrenceI } from "../../../../pojo/OccurrenceI";
import { S25ProfileUtil } from "../../utils/s25.profile.util";
import { S25EventOccurrencesService } from "../s25.event.occurrences.service";
import { S25Util } from "../../../../util/s25-util";
import { S25Reservation } from "../../ReservationI";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";
import { EvOccMappingComponent } from "../../../../standalone/ev-occ-mapping.component";
import { S25Profile, RecordType } from "../../ProfileI";

/**
 * Displays gear icon, lets the user select between 'seperated' and 'merged view'. migration of evdo-menu
 * OTHER NOTES:
 * Commented out "Delete This Segment"  event micro service current not support this feature
 * ACCL-1752 getting very large, don't want adding any new functionality
 *
 */
@Component({
    selector: "s25-ng-occ-menu",
    template: `
        <div class="occ-menu-wrapper" [class.single-profile]="!multiProfiles">
            @if (multiProfiles && ((isSeparatedView && isFirstSegment) || !isSeparatedView)) {
                <div class="occ-menu">
                    <button
                        class="view-select-button c-margin-right--half"
                        [ngClass]="{
                            'aw-button aw-button--primary': !isSeparatedView,
                            'c-textButton': isSeparatedView,
                        }"
                        (click)="onViewChange()"
                    >
                        Merged View
                    </button>
                    <button
                        class="view-select-button"
                        [ngClass]="{
                            'aw-button aw-button--primary': isSeparatedView,
                            'c-textButton': !isSeparatedView,
                        }"
                        (click)="onViewChange()"
                    >
                        Separated View
                    </button>
                </div>
            }
            @if (canEdit) {
                @if (isSeparatedView || !multiProfiles) {
                    <button class="aw-button aw-button--outline" (click)="copySegment()">Copy This Segment</button>
                    <s25-ng-modal #occMappingModal [title]="'Copy Segment Occurrence Mapping'" [size]="'xl'">
                        <ng-template #s25ModalBody>
                            <s25-ng-occ-mapping
                                #occMappingComp
                                [startDate]="startDate"
                                [rsrvs]="rsrvs"
                                [soruceProfileCode]="profileCode"
                                (modelValueChange)="onCopySegmentSubmit($event)"
                            >
                            </s25-ng-occ-mapping>
                        </ng-template>
                    </s25-ng-modal>
                }

                <s25-popover
                    [modelBean]="{ popoverTemplate: newSegmentForm }"
                    [openTrigger]="'click'"
                    [closeTrigger]="'click'"
                >
                    <button class="aw-button aw-button--outline" (click)="addSegment()">Add a New Segment</button>
                </s25-popover>
            }

            @if (canEdit && isSeparatedView) {
                @if (multiProfiles) {
                    <button class="aw-button aw-button--danger--outline" (click)="deleteSegment()">
                        Delete This Segment
                    </button>
                }
            }
            @if (!multiProfiles || (isSeparatedView && isFirstSegment) || !isSeparatedView) {
                <button class="aw-button aw-button--outline" (click)="expandAllToggle()">
                    {{ isExpandAll ? "Collapse All" : "Expand All" }}
                </button>
            }
        </div>

        <ng-template #newSegmentForm>
            <div class="date-time-section">
                @if (profileName()) {
                    <s25-ng-editable-text
                        class="segmentName"
                        [allowEmpty]="false"
                        [min]="2"
                        [max]="40"
                        [alwaysEditing]="true"
                        [val]="profileName()"
                        (valChange)="profileName.set($event)"
                    ></s25-ng-editable-text>

                    @if (!isCopySegment()) {
                        <s25-ng-editable-start-end-datetime
                            [startDatetime]="startDate"
                            (startDatetimeChange)="onDateTimeChange($event, 'start')"
                            [endDatetime]="endDate"
                            (endDatetimeChange)="onDateTimeChange($event, 'end')"
                            [noTimeUpdated]="true"
                        >
                        </s25-ng-editable-start-end-datetime>
                    }

                    <button class="aw-button aw-button--outline c-margin-top--half" (click)="onSubmit(true)">
                        Submit
                    </button>
                }
            </div>
        </ng-template>
    `,
    styles: [
        `
            .occ-menu-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-block: 15px;
            }

            .occ-menu-wrapper.single-profile {
                margin-block: 1rem;
            }

            .occ-menu {
                font-size: 16px !important;
                font-weight: 600;
            }

            .nm-party--on {
                .occ-menu {
                    color: #fff;
                }

                .c-start-end-datetime-picker--wrapper {
                    box-shadow: unset !important;
                }
            }

            .view-select-button.aw-button.aw-button--primary {
                font-size: unset !important;
                border-radius: 7px;
            }

            .date-time-section {
                padding: 10px 20px 20px 20px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvOccMenuComponent implements OnInit {
    @Input() isSeparatedView: boolean;
    @Input() profileId?: number;
    @Input() canEdit?: boolean = false;
    @Input() multiProfiles?: boolean = false;
    @Input() startDate?: Date;
    @Input() endDate?: Date;
    @Input() isFirstSegment: boolean = false;
    @Output() modelValueChange: EventEmitter<EvOccMenuOutput> = new EventEmitter<EvOccMenuOutput>();
    @ViewChild("occMappingComp") occMappingComp: EvOccMappingComponent;
    @ViewChild("occMappingModal") occMappingModal: S25ModalComponent;
    @ViewChild(PopoverComponent) popover: PopoverComponent;

    init: boolean = false;
    profileName = signal<string>("");
    newProfileId = signal<number>(null);
    isCopySegment = signal<boolean>(false);
    mapping: OccurrenceI.Mapping = "one-by-one";
    profile: S25Profile;
    rsrvs: S25Reservation[];
    isExpandAll: boolean = false;
    profileCode: string = "";

    constructor(
        private cd: ChangeDetectorRef,
        private eventOccurrencesService: S25EventOccurrencesService,
    ) {}

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges;
    }

    @Debounce(300) // Avoid double calls
    async copySegment() {
        const profile = S25ProfileUtil.getProfileByProfileId(
            this.eventOccurrencesService.getProfiles() as S25Profile[],
            this.profileId,
        ) as S25Profile;
        if (profile?.occurrenceDefn?.recTypeId === RecordType.RecurrenceGrammar)
            this.profileCode = profile?.occurrenceDefn?.profileCode;
        this.rsrvs = profile.reservations as S25Reservation[];
        this.isSeparatedView = true;
        this.isCopySegment.set(true);
        this.occMappingModal.open();
    }

    //go to seperated view and indicate that a new segment is added
    async addSegment() {
        this.newProfileId.set(await EventService.getNewProfileId());
        this.profileName.set("Segment_" + this.newProfileId());
        this.isCopySegment.set(false);
    }

    @Debounce(300) // Avoid double calls
    onSubmit(addNew: boolean) {
        if (addNew) {
            const data: dataI = {
                startDate: this.startDate,
                endDate: this.endDate,
                profileName: this.profileName(),
                profileId: this.newProfileId(),
                isCopySegment: this.isCopySegment(),
            };
            this.isSeparatedView = true;
            this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: true, data: data });
            this.popover?.close();
        }
    }

    onCopySegmentSubmit(data: dataI) {
        (data.sourceProfileId = this.profileId), (data.isCopySegment = true);
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: true, data: data });
        this.occMappingModal.close();
    }

    onDateTimeChange(newDate: Date, text: "start" | "end") {
        text === "start" ? (this.startDate = newDate) : (this.endDate = newDate);
    }

    onViewChange() {
        const data = {
            profileId: this.profileId,
        };
        this.isSeparatedView = !this.isSeparatedView;
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: false, data: data });
    }

    @Debounce(300) // Avoid double calls
    deleteSegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: false,
            data: { profileId: this.profileId },
            isDelete: true,
        });
    }

    refresh() {
        this.init = false;
        this.cd.detectChanges();
        this.init = true;
        this.cd.detectChanges();
    }

    expandAllToggle() {
        this.isExpandAll = !this.isExpandAll;
        this.eventOccurrencesService.setExpandAll(!this.isExpandAll);
        const elements: any = document.querySelectorAll(".occ-expand-all");
        for (let i = 0; i < elements.length; ++i) {
            elements[i].click();
        }
        this.cd.detectChanges();
    }
}

export type EvOccMenuOutput = {
    isSeparatedView: boolean;
    data?: dataI;
    addNew: boolean;
    isDelete?: boolean;
};

export type dataI = {
    profileId?: number;
    profileName?: string;
    startDate?: Date;
    endDate?: Date;
    sourceProfileId?: number;
    mapping?: OccurrenceI.Mapping;
    isCopySegment?: boolean;
    newCopyRsrvsData?: S25Reservation[];
};
